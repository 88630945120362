<template>
  <div
    class="table-head"
    ref="tableHead"
    :style="tableBodyConfig"
  >
    <div
      class="table"
      ref="thead"
    >
      <div class="thead">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
//= mixins
import { detectDevice } from '@/assets/js/mixins/base/DetectDevice'

export default {
  name: 'tbl-head',

  mixin: [
    detectDevice
  ],

  inject: {
    tblConfig: {}
  },

  computed: {
    getDataLoading () {
      return this.$parent.$parent.dataLoading
    },

    tableBodyConfig () {
      let result
      let width = this.tblConfig.body_w
      let tableWidth = this.tblConfig.table_w

      if (tableWidth > width) {
        result = tableWidth + 'px'
      } else {
        result = width + 'px'
      }

      return {
        width: result
      }
    }
  }
}
</script>
